// Function that returns the appropriate URI for a given target and locale key
// Note: the input object must include all of the fields of the example below

// Example query:
//  InternalLinkField {
//    internal {
//      type
//    }
//    slug
//    type
//  }

export const getURI = (target, key) => {
  const { internal, slug, type } = target
  const contentType = internal.type.replace('Contentful', '')

  let uri = '/'

  let directory = 'unknown'
  switch (type) {
    case 'Activity':
      directory = 'activities'
      break
    case 'Article':
      directory = 'articles'
      break
    case 'Book':
      directory = 'books'
      break
    case 'Video':
      directory = 'videos'
      break
    default:
      break
  }

  switch (contentType) {
    case 'About':
      uri = `/${key}/about/`
      break
    case 'ActivityOrVideoDirectory':
      uri = `/${key}/${slug}/`
      break
    case 'ActivityArticleBookOrVideoDetailPage':
      uri = `/${key}/${directory}/${slug}/`
      break
    case 'DragonBreathing':
      uri = `/${key}/${slug}/`
      break
    case 'Fatherhood':
      uri = `/${key}/${slug}/`
      break
    case 'GenericPage':
      uri = `/${key}/${slug}/`
      break
    case 'Home':
      uri = `/${key}/`
      break
    case 'ServiceDetailPage':
      uri = `/${key}/services/${slug}/`
      break
    case 'ServicesDirectory':
      uri = `/${key}/services/`
      break
    case 'TopicPage':
      uri = `/${key}/topics/${slug}/`
      break
  }

  return uri
}
